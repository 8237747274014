import _ from 'lodash'
export default function Filterable() {
    return {
        filters: {},
        items: [],
        queryString: '',
        apiRoute: null,
        archive: null,
        filterString: null,
        pageNumber: 1,
        maxPages: null,
        nextPage: null,
        loading: false,
        append: false,
        menuOpen: false,
        setupFilters: function(url = window.location.search){
            this.queryString = url.split("?").length > 1 ? url.split("?")[1] : '';
            this.filterString = this.apiRoute;
            if(this.queryString.length){
                this.filterString += "?";
                let params = this.extractParams(this.queryString);
                _.each(params, (option, key) => {
                    this.filterString += `filters[${key}]=${option}`;
                });
            }
        },
        applyFilters: function(url){
            this.setupFilters(url);
            this.loading = true;
            this.menuOpen = false;
            this.queryPosts(this.filterString);
        },
        extractParams: function(queryString){
            var query = {};
            var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
            return query;
        },
        queryPosts: function(url, append = false, callback = null){
            this.append = append;
            fetch(url)
            .then(res => res.json())
            .then(data => {
                if(this.append){
                    this.items = [...this.items, ...data.data];
                } else {
                    this.items = data.data;
                }
                this.loading = false;
                this.pageNumber = data.meta.current_page;
                this.maxPages = data.meta.max_pages;
                this.nextPage = (this.pageNumber < this.maxPages) ? this.pageNumber + 1 : null;
                window.history.pushState('', '', "?"+this.queryString);
                if(callback){
                    callback();
                }
            });
        },
        getPage: function(pageUrl){
            if(!isNaN(pageUrl)){
                this.pageNumber = pageUrl;
            } else {
                this.pageNumber = pageUrl.match(/\/page\/(\d+)\//)[1];
            }
            // load first page results into items array
            // this is necessary because we're switching from PHP to JSON
            if(isNaN(pageUrl) && this.pageNumber == 2){
                this.queryPosts(this.filterString, false, () => {
                    this.loading = true;
                    this.filterString += (this.filterString == this.apiRoute) ? "?page=2" : '&page=2';
                    this.queryPosts(this.filterString, true);
                });
            } else {
                this.loading = true;
                this.filterString += (this.filterString == this.apiRoute) ? "?page="+this.pageNumber : '&page=' + this.pageNumber;
                this.queryPosts(this.filterString, true);
            }
        }
    }
}
