import React, { useEffect, useRef, useState, createRef } from 'react'
import Glide, { Breakpoints } from '@glidejs/glide';

export default function CarouselGlide({
    items=false,
    sliderSettings,
    carouselClasses,
    component,
    extra=false,
    exposedSliderVariable=undefined,
    syncedSliders=undefined
}) {
    if( items && items.length !== 0 ) {
        const [glide] = useState(new Glide(`.${carouselClasses.selector}:not(.glide--carousel)`, sliderSettings))

        var glideSlider = createRef();
        var slidesRefWrapper = createRef();
        var bulletsRefWrapper = createRef();
        var componentName = component;
        useEffect(() => {
            var perView = glide.settings.perView;
            var sliderLength = slidesRefWrapper.current.querySelectorAll('.glide__slide:not(.glide__slide--clone)').length;
            const activeBullet = "glide__bullet--active";
            const bulletsContainerElement = glideSlider.current.querySelector(".glide__bullets");

            // Instantly update bullets
            const changeActiveBullet = (newIndex, containerElement) => {
                const glideDir = containerElement.querySelector(`[data-glide-dir="=${newIndex}"]`);

                containerElement.querySelector(`.${activeBullet}`).classList.remove(activeBullet);

                if (glideDir) 
                    glideDir.classList.add(activeBullet);

                if(componentName == 'CarouselSectional') {
                    moveNav(bulletsRefWrapper);
                }
            };

            // Expose Glide instance if given an output variable
            if (typeof (exposedSliderVariable) !== 'undefined') {
                window[exposedSliderVariable] = glide;
            }
            
            // Handles navigation
            glide.on('run.before', (evt) => {
                var perView = glide.settings.perView;
                var sliderLength = slidesRefWrapper.current.querySelectorAll('.glide__slide:not(.glide__slide--clone)').length;
                var activeSlide = glide.index;
                if( evt.direction === '>' ) {
                    var nextSlide = activeSlide + perView;
                    if (nextSlide >= sliderLength) {
                        glide.index = sliderLength - 1;
                    } else {
                        evt.direction = '|';
                        evt.steps = '>';
                    }
                } else if ( evt.direction === '<') {
                    var prevSlide = activeSlide - perView;
                    if (prevSlide < 0) {
                        if(sliderLength % perView) {
                            var offset = perView - (sliderLength % perView);
                        }  else {
                            offset = 0;
                        }
                        glide.index = prevSlide + offset + 1;
                    } else {
                        evt.direction = '|';
                        evt.steps = '<';
                    }
                }
            })
            glide.on('run', (evt) => {
                if (typeof (syncedSliders) === 'string') {
                    syncedSliders = [syncedSliders];
                } else if (typeof (syncedSliders) !== 'undefined') {
                    syncedSliders.forEach( (sliderID) => {
                        if (evt.direction == '>' || evt.direction == '<') {
                            window[sliderID].go(evt.direction);
                        } else {
                            if (typeof(window[sliderID]) == 'object' && typeof(window[sliderID]).go !== 'undefined') {
                                window[sliderID].go("="+glide.index);
                            }
                        }
                    });

                }
                if (bulletsContainerElement)
                    requestAnimationFrame(() => changeActiveBullet(glide.index, bulletsContainerElement));
            })

            // Checks whether to enable slider based on how many slide items 
            glide.on(['mount.after','resize'],() => {
                var curWindow = window.innerWidth;
                var hasBreakpoint = typeof glide.settings.breakpoints[768];
                var breakpointPerView = hasBreakpoint !== 'undefined' ? glide.settings.breakpoints[768].perView : false;
                // get the mobile breakpoint if on mobile
                if ( curWindow <= 768 && breakpointPerView ) {
                    perView = breakpointPerView;
                } else {
                    perView = glide.settings.perView;
                }
                if( sliderLength > perView ) {
                    glide.enable();
                    glideSlider.current.classList.remove('hide-nav');
                } else {
                    glide.disable();
                    glideSlider.current.classList.add('hide-nav');
                }
                if(componentName == 'CarouselSectional') {
                    bulletsRefWrapper.current.querySelector('.nav-pointer').classList.remove('hidden');
                }
            })

            glide.mount();

            if(componentName == 'CarouselSectional') {
                moveNav(bulletsRefWrapper);
                glide.on('resize', (evt) =>{
                    moveNav(bulletsRefWrapper);
                })
            }

            // cleanup when unmounting component
            return () => glide.destroy()
        }, [])

        var navClasses = {
            'arrows': carouselClasses.arrows, 
            'bullets': carouselClasses.bullets
        };
        return (
            <div ref={glideSlider} className={`glide ${carouselClasses.selector} ${carouselClasses.carousel}`}>
                <div className={`glide__track ${carouselClasses.track ?? ''}`} data-glide-el="track">
                    <div ref={slidesRefWrapper} className={`glide__slides ${carouselClasses.slides ?? ''}`}>
                        <Slide items={items} sliderSettings={sliderSettings} slideClasses={carouselClasses.slide} component={component} extra={extra}></Slide>
                    </div>
                </div>
                <div className="glide__nav-wrap">
                    <RenderNav items={items} component={component} navClasses={navClasses} bulletsRefWrapper={bulletsRefWrapper} extra={extra}></RenderNav>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

// Helper functions
function moveNav(bulletsRefWrapper) {
    var wrapperLeft = bulletsRefWrapper.current.getBoundingClientRect().x;
    var activeBullet = bulletsRefWrapper.current.querySelector('.glide__bullet--active');
    var bulletLeft = activeBullet.getBoundingClientRect().x;
    var bulletWidthOffset = activeBullet.getBoundingClientRect().width / 2;
    var navPointer = bulletsRefWrapper.current.querySelector('.nav-pointer');
    var navPointerWidthOffset = navPointer.offsetWidth / 2;
    var leftOffset = bulletLeft - wrapperLeft;

    navPointer.style.left = ( leftOffset - navPointerWidthOffset + bulletWidthOffset ) + 'px';
}

// DOM elements
function Slide({items,sliderSettings,slideClasses,component, extra}) {
    return(
        <>
            {items.map((item, i)=>{
                return(
                    <div className={`glide__slide ${slideClasses}`} style={{backgroundColor: item.bgColor}} key={i}>
                        <RenderSlide item={item} component={component} extra={extra}></RenderSlide>
                    </div>
                )
            })}
        </>
    )
}

function RenderSlide({item, component, extra}) {
    switch(component) {
        case 'RowPhotoCTA':
            return (
                <div className="flex flex-col jusitfy-center items-center">
                    { item.icon &&
                        <div className="mb-4" dangerouslySetInnerHTML={{__html: item.icon}}>
                        </div>
                    }
                    <h2 className="mb-5 type-preset-4 text-white font-display font-bold text-center" dangerouslySetInnerHTML={{__html: item.title}}>
                    </h2>
                    <div className={`mx-auto  ${item.button ? 'mb-11' : ''} type-preset-6 text-white font-display font-medium text-center w-full max-w-310`} dangerouslySetInnerHTML={{__html: item.text}}>
                    </div>
                    { item.button &&
                        <a
                            className="btn btn--primary"
                            href={item.button.url}
                            target={item.button.target}
                        ><span dangerouslySetInnerHTML={{__html: item.button.title}}></span></a>
                    }
                </div>
            )
            break;
        case 'RowSupporters':
            return (
                <a className="block max-w-263 mx-auto" href={item.website} target="_blank">
                    <div className="mb-4" dangerouslySetInnerHTML={{__html: item.logo}}>
                    </div>
                </a>
            )
            break;
        case 'RowPartnerGrid':
            return (
                <a className="block max-w-263 mx-auto" href={item.externalLink} target="_blank">
                    <div className="mb-4" dangerouslySetInnerHTML={{__html: item.logoMobile}}>
                    </div>
                </a>
            )
            break;
        case 'CarouselInfographicText':
            return (
                <div className="px-gutter">
                    <h2 className="type-preset-2 font-display font-navy font-bold" dangerouslySetInnerHTML={{__html: item.title}}>
                    </h2>
                    <h3 className="mt-5 md:mt-3 type-preset-5 font-display font-navy font-medium" dangerouslySetInnerHTML={{__html: item.text}}>
                    </h3>
                </div>
            )
            break;
        case 'CarouselInfographicImage':
            return (
                <div className="w-full h-full">
                    <div className="w-full h-full bg-cover bg-center" style={{backgroundImage: 'url("'+item.infographic+'")'}}></div>
                    { item.infographicDescription &&
                        <div className="sr-only" dangerouslySetInnerHTML={{__html: item.infographicDescription}}></div>
                    }
                </div>
            )
            break;
        case 'CarouselMediaLarge':
            return (
                <div className="mx-auto max-w-750 w-full h-full">
                    <div className="aspect-w-50 aspect-h-63" dangerouslySetInnerHTML={{__html: item.image}}></div>
                    { item.caption &&
                        <div className="mt-4 type-preset-7 text-dark-gray font-display" dangerouslySetInnerHTML={{__html: item.caption}}></div>
                    }
                </div>
            )
            break;
        case 'CarouselArticleTeasers':
            return (
                <a className={`block mx-auto px-8 pt-11 pb-13 w-full max-w-262 h-full bg-beige-25 hover:bg-navy active:bg-forest-green group text-navy hover:text-white active:text-white duration-250 josh-js ${ item.delay ? item.delay : '' }`}
                    data-josh-anim-name={ item.delay ? 'fadeIn' : '' }
                    href={item.url}
                >
                    <h3 className="type-preset-6 font-display font-bold text-cherry-red" dangerouslySetInnerHTML={{__html: item.kicker}}>
                    </h3>
                    <h2 className="type-preset-5 font-display font-bold" dangerouslySetInnerHTML={{__html: item.title}}>
                    </h2>
                </a>
            )
            break;
        case 'CarouselPartnerSpotlightImage':
            var logo = createRef();
            return (
                <>
                    { item.logo &&
                        <div className="w-full h-auto" ref={logo} dangerouslySetInnerHTML={{__html: item.logo}}>
                        </div>
                    }
                </>
            )
            break;
        case 'CarouselPartnerSpotlightText':
            return (
                <>
                    <p className="type-preset-5 font-display" dangerouslySetInnerHTML={{__html: item.text}}></p>
                    { item.attribution &&
                        <p className="mt-4 type-preset-7 font-display" dangerouslySetInnerHTML={{__html: item.attribution}}></p>
                    }
                </>
            )
            break;
        case 'CarouselPartnerSpotlightButton':
            return (
                <>
                    { item.button &&
                        <a
                            className="slide-btn btn btn--primary md:max-w-236 md:w-full"
                            href="{item.button.url}"
                            target="{item.button.target}"
                            onMouseEnter={() => {
                                    if(item.logo)
                                        logo.current.querySelector('img').classList.add('scale-120');
                                }
                            }
                            onMouseLeave={() => {
                                    if(item.logo)
                                        logo.current.querySelector('img').classList.remove('scale-120');
                                }
                            }
                        ><span dangerouslySetInnerHTML={{__html: item.button.title}}></span></a>
                    }
                </>
            )
            break
        case 'CarouselSectional':
            return (
                <div className="md:px-8 flex justify-center md:justify-start flex-wrap sm:flex-nowrap">
                    { item.icon &&
                        <div className="md:mt-39px mb-9 w-full max-w-120" dangerouslySetInnerHTML={{__html: item.icon}}>
                        </div>
                    }
                    <div className="w-full sm:pl-10">
                        { item.kicker &&
                            <h3 className="mb-4 md:mb-5 type-preset-6 font-display font-bold text-yellow">
                                {item.kicker}
                            </h3>
                        }
                        <h2 className="mb-4 md:mb-3 type-preset-2 font-display font-bold text-white" dangerouslySetInnerHTML={{__html: item.title}}></h2>
                        <p className="mb-8 md:mb-9 type-preset-5 font-body text-white" dangerouslySetInnerHTML={{__html: item.text}}></p>
                        { item.button &&
                            <a
                                className="btn btn--yellow"
                                href={item.button.url}
                                target={item.button.target}
                            ><span dangerouslySetInnerHTML={{__html: item.button.title}}></span></a>
                        }
                    </div>
                </div>
            )
            break;
        case 'CarouselSuccessStories':
            return (
                <a href={item.url} className={`block w-full max-w-360 group duration-250 josh-js ${ item.delay ? item.delay : '' }`}
                    data-josh-anim-name={ item.delay ? 'fadeIn' : '' }
                >
                    <div className="overflow-hidden aspect-w-3 aspect-h-2 bg-navy mb-8" dangerouslySetInnerHTML={{__html: item.image}}>
                    </div>
                    { item.kicker &&
                        <h3 className="mb-2 font-display type-preset-6-5 text-kelly-green-100 font-bold" dangerouslySetInnerHTML={{__html: item.kicker}}>
                        </h3>
                    }
                    <h2 className="font-display type-preset-5 text-navy font-bold group-hover:underline group-hover:text-af-green group-focus:text-yellow duration-250" dangerouslySetInnerHTML={{__html: item.title}}>
                    </h2>
                </a>
            )
            break;
        case 'RowPersonnel':
            return (
                <div className="w-full max-w-160 mx-auto text-center">
                    <a href={item.url} className="inline-block font-display text-navy hover:text-af-green active:text-yellow group transition-all duration">
                        <div className={`flex justify-center items-center w-150 h-150 mx-auto rounded-full overflow-hidden ${item.headshot ? '' : 'bg-beige-100'}`} dangerouslySetInnerHTML={{__html: item.headshot}}>
                        </div>
                        <div className="type-preset-6-5 font-bold mt-4" dangerouslySetInnerHTML={{__html: item.name}}>
                        </div>
                        { item.title &&
                            <div className="type-preset-6-5 text-navy" dangerouslySetInnerHTML={{__html: item.title}}>
                            </div>
                        }
                    </a>
                </div>
            )
            break;
        case 'CarouselImages':
            return (
                <div className="w-full max-w-750 mx-auto text-center">
                    <img className="mx-auto" src={item} />
                </div>
            )
            break;
        default:
            // Dumps content of item
            return (
                <div>
                    <pre className="whitespace-pre-wrap">
                        {JSON.stringify(item, null, '\t')}
                    </pre>
                </div>
            )
            return null;
    }
}

function RenderNav({items,component,navClasses,bulletsRefWrapper,extra}) {
    switch(component) {
        case 'RowPhotoCTA':
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} absolute left-0 right-0 flex justify-center w-full `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return <button key={i} className="glide__bullet duration-250 mx-2 rounded-full bg-beige-200" data-glide-dir={`=${i}`}></button>
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        case 'CarouselInfographicText':
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} absolute left-0 right-0 flex justify-center w-full `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return <button key={i} className="glide__bullet duration-250 mx-2 rounded-full bg-beige-25" data-glide-dir={`=${i}`}></button>
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        case 'CarouselPartnerSpotlightButton':
        case 'CarouselPartnerSpotlightImage':
        case 'CarouselInfographicImage':
            return(<></>);
            break;
        case 'CarouselArticleTeasers':
        case 'RowSupporters':
        case 'RowPartnerGrid':
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} absolute left-0 right-0 rounded-full flex justify-center w-full `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return (
                                <button key={i} className={`glide__bullet hidden duration-250 mx-2 rounded-full bg-beige-200 ${ i % 4 ? 'md:hidden' : 'md:block' } `} data-glide-dir={`=${i}`}></button>
                            )
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        case 'CarouselPartnerSpotlightText':
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} mx-auto absolute left-0 right-0 rounded-full flex justify-center max-w-260 `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return <button key={i} className="glide__bullet duration-250 mx-2 rounded-full bg-beige-200" data-glide-dir={`=${i}`}></button>
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        case 'CarouselSectional':
            return (
                <>
                    <div className={`glide__bullets text-link ${navClasses.bullets} absolute left-0 right-0 flex sm:justify-center w-full `} ref={bulletsRefWrapper} data-glide-el="controls[nav]">
                        { items.map((item, i)=>{
                            return <button key={i} className="glide__bullet ml-14 duration-250 hidden sm:inline-block font-medium type-preset-6-5 font-display text-beige-100 underline" data-glide-dir={`=${i}`}>{item.navAnchor}</button>
                        })}
                        { extra &&
                            <a className="glide__bullet ml-30px sm:ml-14 duration-250 type-preset-6-5 font-display font-medium text-af-green underline" target={extra.seeAllLink.target} href={extra.seeAllLink.url} dangerouslySetInnerHTML={{__html: extra.seeAllLink.title}}></a>
                        }
                        <div className="invisible sm:visible nav-pointer hidden absolute sm:duration-250"></div>
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        case 'CarouselSuccessStories':
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} absolute left-0 right-0 rounded-full hidden md:flex justify-center w-full `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return <button key={i} className={`glide__bullet duration-250 mx-2 rounded-full bg-beige-200 ${ i % 3 ? 'md:hidden' : 'md:block' }`} data-glide-dir={`=${i}`}></button>
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break
        case 'CarouselMediaLarge':
        case 'RowPersonnel':
            return (
                <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
            )
            break;
        case 'CarouselImages':
            return (
                <>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
        default: 
            return (
                <>
                    <div className={`glide__bullets ${navClasses.bullets} absolute left-0 right-0 rounded-full flex w-full `} data-glide-el="controls[nav]">
                        {items.map((item, i)=>{
                            return <button key={i} className="glide__bullet duration-250 mx-2 rounded-full bg-beige-200" data-glide-dir={`=${i}`}></button>
                        })}
                    </div>
                    <RenderArrows component={component} arrowClasses={navClasses.arrows} leftDir="<" rightDir=">"></RenderArrows>
                </>
            )
            break;
    }
}

function RenderArrows({component, arrowClasses, leftDir, rightDir}) {
    return (
        <div className={`glide__arrows duration-250 ${arrowClasses}`} data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left duration-250" data-glide-dir={leftDir}></button>
            <button className="glide__arrow glide__arrow--right duration-250" data-glide-dir={rightDir}></button>
        </div>
    )
}
