import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../hooks/usePagination';

const Pagination = ({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    maxPages,
    className
}) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    maxPages
  });
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container justify-center items-center flex mt-6 pt-13 type-preset-6-5', { [className]: className })}
    >
      <li
        className={classnames('pagination-item font-body cursor-pointer pl-2 pr-2 h-9 md:h-11 w-auto flex items-center justify-center rounded-full mx-1 duration-300', {
          'inactive no-underline text-navy': currentPage === 1,
          'underline text-af-green hover:text-navy active:text-yellow': currentPage !== 1,
        })}
        onClick={onPrevious}
      >
        <span className="font-body font-normal type-preset-6-5 inline-block mr-4">Previous</span>
      </li>
      <li
        className={classnames('pagination-item font-body cursor-pointer pl-2 pr-2 h-9 md:h-11 w-9 md:w-11 text-navy flex items-center justify-center rounded-full mx-1 duration-300 bg-beige-25 hover:bg-navy active:bg-yellow hover:text-white active:text-white', {
          inactive: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <svg className="h-5 w-5 flip-x" fill="currentColor" stroke="currentColor" viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z'/>
        </svg>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key={index} className="pagination-item font-body dots pl-2 pr-2">&#8230;</li>;
        }

        return (
          <li
            className={classnames('pagination-item font-body cursor-pointer pl-2 pr-2 h-9 md:h-11 w-9 md:w-11 text-navy flex items-center justify-center rounded-full mx-1 duration-300 hover:bg-navy active:bg-yellow hover:text-white active:text-white', {
              'bg-af-green text-navy': pageNumber === currentPage
            })}
            key={index}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item font-body cursor-pointer pl-2 pr-2 h-9 md:h-11 w-9 md:w-11 text-navy flex items-center justify-center rounded-full mx-1 duration-300 bg-beige-25 hover:bg-navy active:bg-yellow hover:text-white active:text-white', {
          inactive: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <svg className="h-5 w-5" fill="currentColor" stroke="currentColor" viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z'/>
        </svg>
      </li>
      <li
        className={classnames('pagination-item font-body cursor-pointer pl-2 pr-2 h-9 md:h-11 w-auto flex items-center justify-center rounded-full mx-1 duration-300', {
          'inactive no-underline text-navy': currentPage === lastPage,
          'underline text-af-green hover:text-navy active:text-yellow': currentPage !== lastPage,
        })}
        onClick={onNext}
      >
        <span className="font-body font-normal type-preset-6-5 inline-block ml-4">Next</span>
      </li>
    </ul>
  );
};

export default Pagination;
