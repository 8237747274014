import React, { useEffect, useRef, useState } from 'react'
import { searchTrees } from '../classes/API'
import Select from 'react-select'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import Pagination from './Pagination';

export default function ChampionTrees ({ids=[],filters=[],states=[],order=[],direction=[]})
{
    const [trees, setTrees] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(true);
    const [filtersOpen, setFiltersOpen] = useState(false);

    let searchParams = {
        'type': 'tree',
        'per_page': '8',
        'page': 1
    };

    if(ids.length > 0){
        searchParams['ids'] = ids;
    }

    useEffect(() => {
        (async () => {
            let response = await searchTrees(searchParams)
            let json = await response.json()
            setCount(json.meta.count)
            setPages(json.meta.max_pages)
            setPageNumber(json.meta.current_page)
            setTrees(json.data)
            setLoading(false);
        })()
    }, []);

    async function handleFilterSubmit(opts){
        setLoading(true);

        let searchParams = {
            'type': 'tree',
            'per_page': '8',
            'page': pageNumber
        };

        _.each(opts, (option, key) => {
            if(typeof option == "string"){
                searchParams[key] = option;
            } else if('value' in option){
                searchParams[key] = option.value;
            } else {
                _.each(Object.keys(option), (filter) => {
                    if(option[filter] !== null){
                        searchParams[`${key}[${filter}]`] = option[filter].value;
                    }
                })
            }
        })

        let response = await searchTrees(searchParams)
        let json = await response.json()
        setCount(json.meta.count)
        setPages(json.meta.max_pages)
        setPageNumber(json.meta.current_page)
        setTrees(json.data)
        setLoading(false);
    }

    function toggleFilters(close = false){
        let menu = document.getElementById('filterWrap');
        let toggle = document.getElementById('filterToggle');
        let filterOpen = (close) ? false : !filtersOpen;

        setFiltersOpen(filterOpen);

        let style = (toggle.currentStyle ? toggle.currentStyle.display : getComputedStyle(toggle, null).display) !== 'none' && filterOpen ? 'max-height:' + menu.scrollHeight + 'px' : '';

        menu.style = style;
    }

    return (
        <div className="champion-trees container mx-auto relative">
            <Formik
                initialValues={{}}
                onSubmit={
                    (values) => {
                        setPageNumber(1);
                        handleFilterSubmit(values);
                        toggleFilters(true);
                        window.scrollTo({
                            top: (document.querySelector('[data-react-component=championTrees]').getBoundingClientRect().top + window.pageYOffset - 180),
                            behavior: 'smooth'
                        });
                    }
                }
                onReset={
                    () => {
                        handleFilterSubmit({});
                    }
                }
            >
                {({handleSubmit, values, setFieldValue, handleReset}) => (
                <Form className="flex justify-between items-start">
                    <Filters options={filters} states={states} order={order} direction={direction} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset} setFieldValue={setFieldValue} filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen} toggleFilters={toggleFilters} />
                    {loading ?
                        <div className="relative h-32 w-full">
                            <svg style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}} xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z" /><path d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" /></path></svg>
                        </div>
                    : (trees && trees.length) ?
                        <div className="champion-trees-results w-full md:pl-16 pt-11 md:pt-0 mt-11 md:mt-0">
                            <Trees trees={trees} count={count} />
                            <Pagination
                                onPageChange={(pageNum) => {
                                    window.scrollTo({
                                        top: (document.querySelector('[data-react-component=championTrees]').getBoundingClientRect().top + window.pageYOffset - 180),
                                        behavior: 'smooth'
                                    });
                                    setPageNumber(pageNum);
                                    handleSubmit();
                                }}
                                totalCount={count}
                                currentPage={pageNumber}
                                maxPages={pages}
                            />
                        </div>
                    :
                        <div className="container-xs mx-auto pt-11 md:pt-0 mt-11 md:mt-0">
                            <p className="border-b border-gray-200 text-gray-600 pb-4 mb-4">No Results</p>
                        </div>
                    }
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Filters({options, states, order, direction, setPageNumber, handleSubmit, handleReset, setFieldValue, filtersOpen, setFiltersOpen, toggleFilters}){
    return (
        <div className="champion-trees-filters w-full md:max-w-272 absolute md:static top-0 left-0 z-20">
            <div className="md:hidden px-gutter">
                <a id="filterToggle" className={`flex justify-between items-center pb-2 transition-all duration-500 border-b-2 ${(filtersOpen) ? 'border-transparent' : 'border-af-green'}`}
                    onClick={(e) => {
                        e.preventDefault();
                        toggleFilters();
                    }}
                >
                    <span className="block type-preset-6 font-display font-bold text-navy">Filter</span>
                    <div className={`transform transition-all duration-500 ${(filtersOpen) ? 'rotate-180' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={9} className="w-8 h-auto">
                            <path d="m.29 1.927 9.197 6.646 9.515-6.632L17.907.369l-.16-.23-8.229 5.756L1.58.14l-.08.11z" fill="currentColor" fillRule="evenodd"></path>
                        </svg>
                    </div>
                </a>
            </div>
            <div id="filterWrap" className="px-gutter md:px-0 mt-3 md:mt-0 overflow-auto md:overflow-visible transition-all duration-500 max-h-0 md:max-h-full bg-white">
                <h3 className="filter-group-title type-preset-6 font-display font-bold text-navy mb-8">Filter by:</h3>
                {options.map((option, key) => {
                    return (
                        <FilterGroup key={key} title={option['title']} name={option['name']} options={option['options']} flattenedOpts={true} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset} />
                    )
                })}
                <FilterGroup title={states['title']} name={states['name']} options={states['options']} flattenedOpts={false} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset}/>

                <h3 className="filter-group-title type-preset-6 font-display font-bold text-navy mb-8">Order by:</h3>
                <FilterGroup title="Order" name="sort" options={order['options']} flattenedOpts={false} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset}/>
                <FilterGroup title="Largest to Smallest" name="direction" options={direction['options']} flattenedOpts={false} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset}/>
                <div className="button-container pb-11 md:pb-0">
                    <button type="submit" className="btn btn--primary">Submit Search</button>
                    <button type="submit" onClick={() => {
                        handleReset;
                        setFieldValue('filters[genus]', null);
			location.reload(); 
			{options.map((option, key) => {
				console.log(option['name']);
			})}
                    }} className="type-preset-7 font-body underline text-navy ml-8">Clear Search</button>
                </div>
            </div>
        </div>
    )
}

function FilterGroup({title, name, options, flattenedOpts, setPageNumber, handleSubmit, handleReset}){
    let DropdownIndicator = function DropdownIndicator() {
        return <svg width="19" height="9" xmlns="http://www.w3.org/2000/svg"><path d="m.29 1.934 9.197 6.625L19 1.948 17.906.38l-.16-.229L9.518 5.89 1.58.154l-.08.11z" fill="#0B193B" fillRule="evenodd"/></svg>;
    }
    return (
        <div className="filter-field-container filter-field-select">
            <div className="filter-field-select-container">
                <Field name={name} className="filter-field filter-field-select">
                    {({form, field}) => <StyledSelect
                        className="champion-trees-select font-body "
                        placeholder={title}
                        options={
                            Object.entries(options).map((option) => (flattenedOpts ? {label: _.upperFirst(_.toLower(option[1])), value: option[1]} : {label: option[1], value: option[0]}))
                        }
                        onChange={(value) => {
                            form.setFieldValue(field.name, value);
                            setPageNumber(1);
                            handleSubmit();
                        }}
                        components={{
                            DropdownIndicator: DropdownIndicator,
                        }}
                    />}
                </Field>
            </div>
        </div>
    )
}


function StyledSelect ({width, ...props}) {
    return (
        <Select
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: '100%'
                }),
                control: (provided) => ({
                    ...provided,
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    borderRadius: 0,
                    width: '100%',
                    maxWidth: '270px',
                    minHeight: '65px',
                    margin: '0 auto',
                    fontWeight: 'normal',
                    backgroundColor: '#f4f1ed',
                    padding: "20px 16px"
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: '#495b71'
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: '#495b71'
                }),
                indicatorSeparator: (provided) => ({
                    display: 'none'
                }),
                dropdownIndicator: (provided) => ({
                    color: '#0b193b'
                }),
                input: (provided) => ({
                    ...provided,
                    color: 'transparent',
                    opacity: '0',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                }),
                option: (provided) => ({
                    ...provided,
                    color: '#0b193b',
                    padding: '16px',
                    fontSize: '18px',
                    backgroundColor: '#faf8f7',
                    "&:hover": {
                        backgroundColor: '#e1dcd5',
                    }
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '0px',
                }),
                menu: (provided) => ({
                    ...provided,
                    padding: '0px',
                    borderRadius: 0,
                    backgroundColor: "#faf8f7",
                    border: "none",
                    margin: "0"
                }),
                menuList: (provided) => ({
                    ...provided,
                    border: "none",
                    margin: "0"
                }),
                classNamePrefix: (provided) => ({
                   classNamePrefix: "champion-trees-filter-"
                })

            }}
            {...props}
            />
    )
}

function Trees({trees, count}){
    return (
        <div className="champion-trees-list">
            <p className="font-body italic">There are {count} Trees in the Official Register:</p>
            {trees.map(tree => (
                <Tree key={tree.ID} tree={tree} />
            ))}
        </div>
    )
}

function Tree({tree}){

    return (
        <article className="tree-container md:flex ">
            <img
                className="tree-image rounded-full"
                src={tree.images[0]}
                alt={tree.title}
            />
            <div className="tree-meta-information flex justify-between flex-1">
                <div className="content">
                    <h3 className="title font-display type-preset-5 font-bold" dangerouslySetInnerHTML={{__html: tree.title}}></h3>
                    <p className="font-body italic">{tree.scientific_name}</p>
                    <p className="font-display font-bold">State: <span className="font-body font-regular">{tree.state}</span></p>
                    <p className="font-display font-bold mb-5">County: <span className="font-body font-regular">{tree.county}</span></p>
                    <a href={tree.link} className="btn btn--primary">View Profile</a>
                </div>
                <div className="points rounded-full bg-af-green-100 text-center inline-block flex flex-col justify-center self-start">
                    <span className="font-display type-preset-5 font-bold">{tree.points}</span>
                    <span className="font-display font-regular type-preset-7">Points</span>
                </div>
            </div>
        </article>

    )
}
