require('alpinejs')
import React from 'react';
import ReactDOM from 'react-dom'
import ReactComponentLoader from './classes/ReactComponentLoader'
import Josh from './classes/joshjs'
import Rellax from './classes/rellax'

/** Vanilla JS components */

/** Alpine JS components */
window.siteHeader = require('./components/siteHeader').default
window.Collapsable = require('./components/Collapsable').default
window.Filterable = require('./components/Filterable').default

/** React JS components */
ReactComponentLoader({
    'mainMenu': require('./components/MainMenu/').default,
    'carouselGlide': require('./components/CarouselGlide').default,
    'newsroom': require('./components/Newsroom').default,
    'mainSearch': require('./components/mainSearch').default,
    'championTrees': require('./components/ChampionTrees').default,
});

// initialize joshjs (run after loading components)
if (document.querySelectorAll('.josh-js').length) {
    const josh = new Josh({
          onDOMChange: true
    });
}

// initialize rellax elements
if (document.getElementsByClassName('rellax').length > 0) {
    var rellaxElements = document.querySelectorAll('.rellax');
    Array.prototype.forEach.call(rellaxElements, function(el, i){
        var rellax = new Rellax(el, {
            breakpoints:[640, 768, 1024],
            wrapper: el.closest('section'), // parallax behavior is relative to the parent <section>
            relativeToWrapper: true,
        });
    });
}


// Sticky Header 
let header = document.getElementById("site-header");
let headerContainer = document.querySelector(".site-header-container");
let sticky = header.offsetTop;

window.onscroll = function() {
    if (window.pageYOffset > sticky) {
        if (window.pageYOffset >= 50) {
            header.classList.add("sticky-header-bg");
        } else {
            header.classList.remove("sticky-header-bg");
        }
  } else {
    header.classList.remove("sticky-header-bg");
  }
};

