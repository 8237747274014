import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import { searchArticles } from '../classes/API'
import Pagination from './Pagination';

export default function Newsroom ({filters=[], articleType=0}){
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [allCategories, setAllCategories] = useState(true);
    const [allArchives, setAllArchives] = useState(true);
    const [type, setType] = useState(articleType);
    const [filtersOpen, setFiltersOpen] = useState(false);

    useEffect(() => {
        (async () => {
            let response = await searchArticles({'filters[article-type]': type})
            let json = await response.json()
            setCount(json.meta.count)
            setPages(json.meta.max_pages)
            setPageNumber(json.meta.current_page)
            setResults(json.data);
            setLoading(false);
        })()
    }, []);

    async function applyFilter(opts){
        setLoading(true);

        let args = {};
        _.each(opts, (option, key) => {
            if(typeof option == "string"){
                args[key] = option;
            } else if(_.isArray(option)){
                args[key] = option.join(",");
            } else {
                _.each(Object.keys(option), (filter) => {
                    args[`${key}[${filter}]`] = option[filter].join(",");
                })
            }
        })

        args['page'] = args['page'] ?? pageNumber;
        args['filters[article-type]'] = type;

        if(!('filters[category]' in args) || args['filters[category]'] == "" || args['filters[category]'] == ["0"]){
            setAllCategories(true);
        } else {
            setAllCategories(false);
        }
        if(!('archive' in args) || args['archive'] == "" || args['archive'] == ["0"]){
            setAllArchives(true);
        } else {
            setAllArchives(false);
        }

        let response = await searchArticles(args);
        let json = await response.json();
        setCount(json.meta.count);
        setPages(json.meta.max_pages);
        setPageNumber(json.meta.current_page);
        setResults(json.data);
        setLoading(false);
    }

    function toggleFilters(close = false){
        let menu = document.getElementById('filterWrap');
        let toggle = document.getElementById('filterToggle');
        let filterOpen = (close) ? false : !filtersOpen;

        setFiltersOpen(filterOpen);

        let style = (toggle.currentStyle ? toggle.currentStyle.display : getComputedStyle(toggle, null).display) !== 'none' && filterOpen ? 'max-height:' + menu.scrollHeight + 'px' : '';

        menu.style = style;
    }

    return (
        <div>
            <Formik
                initialValues={
                    {'filters': {'category': ['0']}}
                }
                onSubmit={
                    (values) => {
                        setPageNumber(1);
                        applyFilter(values);
                        toggleFilters(true);
                    }
                }
                onReset={() => {
                    setPageNumber(1);
                    applyFilter({'filters[article-type]': type});
                }}
            >
                {({handleSubmit, values, setFieldValue, handleReset, resetForm}) => (
                    <Form onChange={() => {
                        window.scrollTo({
                            top: (document.querySelector('[data-react-component=newsroom]').getBoundingClientRect().top + window.pageYOffset - 180),
                            behavior: 'smooth'
                        });
                        setPageNumber(1);
                        handleSubmit();
                    }}>
                    <div className="container mx-auto">
                        <div className="w-full max-w-1140 mx-auto md:flex relative">
                            <Results results={results} loading={loading} totalCount={count} pageNumber={pageNumber} setPageNumber={setPageNumber} maxPages={pages} handleSubmit={handleSubmit} />
                            <Filters filters={filters} setFieldValue={setFieldValue} handleSubmit={handleSubmit} handleReset={resetForm} setPageNumber={setPageNumber} allCategories={allCategories} allArchives={allArchives} filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen} toggleFilters={toggleFilters} />
                        </div>
                    </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

function Filters({filters, setFieldValue, handleSubmit, handleReset, setPageNumber, allCategories, allArchives, filtersOpen, setFiltersOpen, toggleFilters}){
    return (
        <div className="w-full md:max-w-263 absolute z-20 md:relative top-0 inset-x-0">
            <div className="md:hidden">
                <a id="filterToggle" className={`flex justify-between items-center pb-2 transition-all duration-500 border-b-2 ${(filtersOpen) ? 'border-transparent' : 'border-af-green'}`}
                    onClick={(e) => {
                        e.preventDefault();
                        toggleFilters();
                    }}
                >
                    <span className="block type-preset-6 font-display font-bold text-navy">Filter</span>
                    <div className={`transform transition-all duration-500 ${(filtersOpen) ? 'rotate-180' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={9} className="w-8 h-auto">
                            <path d="m.29 1.927 9.197 6.646 9.515-6.632L17.907.369l-.16-.23-8.229 5.756L1.58.14l-.08.11z" fill="currentColor" fillRule="evenodd"></path>
                        </svg>
                    </div>
                </a>
            </div>
            <div id="filterWrap" className="mt-3 md:mt-11 overflow-auto transition-all duration-500 max-h-0 md:max-h-full md:overflow-visible bg-white">
                {Object.entries(filters).map(group => {
                    return (
                        <div key={group[1].taxonomy} className="border-t-2 border-l-2 border-b-2 border-af-green p-11 filter-box mb-8 md:mb-11">
                            <h3 className="type-preset-6 font-display font-bold text-navy mb-5">{group[1].label}</h3>
                            <ul>
                                <li className="mb-3">
                                    <a
                                        className={`type-preset-7 font-display cursor-pointer ${(group[1].taxonomy == 'archive' ? allArchives : allCategories) ? 'text-af-green no-underline' : 'text-navy underline'}`}
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                setFieldValue(group[1].taxonomy, []);
                                                window.scrollTo({
                                                    top: (document.querySelector('[data-react-component=newsroom]').getBoundingClientRect().top + window.pageYOffset - 180),
                                                    behavior: 'smooth'
                                                });
                                                handleSubmit();
                                            }
                                        }
                                    >
                                        {group[1].default}
                                    </a>
                                </li>
                                {Object.entries(group[1].options).map(checkbox => {
                                    return (
                                        <li key={`${group[1].taxonomy}_${checkbox[0]}`} className="mb-3">
                                            <label className="type-preset-7 font-display text-navy cursor-pointer">
                                              <Field className="form-checkbox h-5 w-5 text-teal-400 hover:text-teal-700 border-2 border-teal-400 hover:border-teal-700 mr-2" type="checkbox" name={group[1].taxonomy} value={checkbox[0]} />
                                              <span className="underline">{checkbox[1]}</span>
                                            </label>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function Results({results, loading, totalCount, pageNumber, setPageNumber, maxPages, handleSubmit}){
    return (
        <div className="w-full pt-11 md:pt-0 md:pr-96px">
            {loading ?
                <div className="relative h-32 w-full">
                    <svg style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}} xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z" /><path d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" /></path></svg>
                </div>
            : (results && results.length) ?
                <div>
                    <div className="newsroom-list max-w-797 -mx-gutter md:-mx-8">
                        {results.map(post => (
                            <Post key={post.ID} post={post} />
                        ))}
                    </div>
                    <Pagination
                        onPageChange={(pageNum) => {
                            window.scrollTo({
                                top: (document.querySelector('[data-react-component=newsroom]').getBoundingClientRect().top + window.pageYOffset - 180),
                                behavior: 'smooth'
                            });
                            setPageNumber(pageNum);
                            handleSubmit();
                        }}
                        totalCount={totalCount}
                        currentPage={pageNumber}
                        maxPages={maxPages}
                    />
                </div>
            :
                <div>
                    <p>No Results</p>
                </div>
            }
        </div>
    )
}

function Post({post}){
    return (
        <article className="hover:bg-af-green-100 active:bg-beige-200 px-gutter md:px-8 transition-colors duration">
            <a href={post.url} className="block border-t border-beige-200 hover:border-transparent transition-colors duration pt-8 md:pt-11 pb-10 md:pb-14">
                <div className="max-w-653">
                    <div className="type-preset-6-5 font-display font-bold text-af-green mb-6px">{post.type}</div>
                    <h2 className="type-preset-4 font-display font-bold text-navy mb-2 md:mb-3" dangerouslySetInnerHTML={{__html: post.post_title}}></h2>
                    <div className="type-preset-6-5 font-display font-bold text-navy mb-4">{post.author ? 'By '+post.author+' | ' : ''}{post.date}</div>
                    <p className="type-preset-6 font-body text-navy" dangerouslySetInnerHTML={{__html: post.summary}}></p>
                </div>
            </a>
        </article>
    )
}
