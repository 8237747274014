import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'

export default function Accordion({ items = [], title = false, contentClass, open = false }) {
    const [currentTab, setCurrentTab] = useState(open);
    return (
        <div className=''>

            {title !== false &&
                <h2 className={"mb-6 text-center font-headline leading-tight text-preset-7 text-white"}>
                    {title}
                </h2>
            }

            {items.map((item, i) => {
                return (
                    <Item key={`${i}-item-accordion`} id={i} setCurrentTab={setCurrentTab} currentTab={currentTab} item={item} contentClass={contentClass} />
                );
            })}
        </div>
    )
}

function Item({ id, item, setCurrentTab, currentTab, contentClass }) {
    const eventClick = (id)=>{
        if(currentTab === id){
            setCurrentTab(false);
        } else {
            setCurrentTab(id);
            item.onOpen();
        }
    };

    if(item.open){
        // setCurrentTab(id);
        item.open = false;
        item.active = true;
    }

    return <article className={`${id === currentTab ? 'active': ''}`}>
        <header onClick={() => eventClick(id)} className={`${id === currentTab ? '' : ''} relative py-4 px-gutter font-bold type-preset-6`}>
            <span className={`${(item.active == true) ? 'border-b-2 border-teal' : ''} inline-block`} dangerouslySetInnerHTML={{ __html: item.title }}></span>
            <div className={`transform origin-center transition-all duration-500 ${(id === currentTab) ? 'rotate-180' : ''} absolute top-1/2 -translate-y-1/2 right-30 mx-3`}>
                <svg xmlns="http://www.w3.org/2000/svg" width={19} height={9} className="w-19 h-auto">
                    <path d="m.29 1.927 9.197 6.646 9.515-6.632L17.907.369l-.16-.23-8.229 5.756L1.58.14l-.08.11z" fill="currentColor" fillRule="evenodd"></path>
                </svg>
            </div>
        </header>
        <div
            id={`menu-component_${id}`}
            className={`${contentClass ? contentClass : ''} overflow-hidden transition-all duration-500`}
            style={{
                maxHeight: `${id === currentTab ? document.getElementById('menu-component_'+id).scrollHeight+'px' : '0px'}`
            }}
            dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
    </article>;
}
