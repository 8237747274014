import React, { Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import Items from './components/Items';

import Accordion from '../Accordion';

export default function MobileMenu({
    items,
    openSearch,
    toggleMenu,
    openMenu,
    donateButton }) {
    return (
        <div className="mobile-menu">
            <Transition.Root show={openMenu} as={Fragment}>
                <div className="absolute z-20 top-74 bottom-0 right-0 left-0 overflow-hidden w-screen h-screen">
                    <div className="mobile-menu__screen fixed top-74 bottom-0 right-0 left-0 flex w-screen">
                        <Transition.Child
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        className="h-full"
                        >
                        <div className="relative w-screen h-full">
                            <div id="menuScroll" className="h-full flex flex-col bg-navy text-white font-display font-bold type-preset-7 overflow-y-scroll">
                                <div className="p-0 relative pb-16">
                                    <Accordion items={items.map((group, index) => (
                                            {
                                                title: group.title,
                                                open: group.open,
                                                onOpen: function(){
                                                    // document.getElementById('menuScroll').scrollTo({
                                                    //     top: 0,
                                                    //     behavior: 'instant'
                                                    // });
                                                },
                                                content: ReactDOMServer.renderToStaticMarkup(<ItemContent toggleMenu={toggleMenu} items={group.child} />)
                                            }
                                        )
                                    )}/>
                                    <div className="flex justify-between items-center text-center mb-11 px-gutter absolute inset-x-0 bottom-0">
                                        { donateButton && <a className="btn btn--yellow w-full"
                                            href={donateButton.url}
                                            target={donateButton.target}>
                                            <span>{donateButton.title}</span>
                                        </a> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Transition.Child>
                    </div>
                </div>
            </Transition.Root>
        </div>);
}

function ItemContent({items, toggleMenu}) {
    return (
        <div style={{backgroundColor: '#152e6e'}}>
            {
                items.map((item, i) => {
                    return (
                        <div key={i} className="px-gutter py-8 border-b border-white border-opacity-25">
                            <h3 className="type-preset-6-5 font-bold">
                                <a
                                    className={`block text-white`} href={item.url}>
                                        <span className={`${item.active ? 'active-item border-b-2 border-teal' : 'inactive-item'}`} dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                    </a>
                            </h3>
                            {('childvalue' in item) &&
                                <ul className="pt-10px">
                                    {item.childvalue.map((child, idx) => (
                                        <li className="" key={idx}>
                                            <a
                                                href={child.url}
                                                className={`block py-10px type-preset-7 font-normal text-white`}
                                            >
                                                <span className={`${(child.active == true) ? 'active-item border-b-2 border-teal' : 'inactive-item'}`} dangerouslySetInnerHTML={{ __html: child.title }}></span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    )
                })
            }


        </div>
    );
}
