export default function siteHeader()
{
    return {
        searchOpen: false,
        query: '',
        submitHandler: function(){
            let searchTerms = encodeURIComponent(this.query);
            let searchBase = document.getElementById('searchForm').getAttribute('action');
            window.location.href = searchBase+searchTerms;
        }
    }
}
