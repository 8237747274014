export default function Collapsable() {
    return {
        collapsed: true,
        collapsedHeight: 0,
        duration: 250,
        timeOut: null,
        options: null,
        init(collapsedHeight = 0, duration = 250, options = null) {
            this.collapsedHeight = collapsedHeight;
            this.duration = duration;
            this.options = options;
            this.$refs.contentWrap.style.height = this.collapsedHeight + 'px';
            this.timeOut = setTimeout(()=>{},0);
        },
        toggle() {
            this.collapsed = !this.collapsed;
            clearTimeout(this.timeOut);
            let obj = this;
            if (this.collapsed) {
                this.$refs.contentWrap.style.height = this.$refs.content.offsetHeight + 'px';
                this.timeOut = setTimeout(function() {
                    obj.$refs.contentWrap.style.height = obj.collapsedHeight + 'px';
                }, 1);
            } else {
                this.$refs.contentWrap.style.height = this.$refs.content.offsetHeight + 'px';
                this.timeOut = setTimeout(function() {
                    obj.$refs.contentWrap.style.height = 'auto';
                }, obj.duration);
            }
        }
    }
}