import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { XIcon, SearchIcon } from '@heroicons/react/outline';
import _ from 'lodash'
import { searchContent } from '../classes/API'
import Pagination from './Pagination';

export default function mainSearch ({term=''}){

    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [currentTerm, setCurrentTerm] = useState(term);
    const [loading, setLoading] = useState(false);
    const [hasSubmitCompleted, setHasSubmitCompleted] = useState(false);

    useEffect(() => {
        let args = {
            s: currentTerm
        };
        handleFilterSubmit(args);
    }, []);

    async function handleFilterSubmit(opts){
        setLoading(true);
        setHasSubmitCompleted(false);

        let args = {};
        _.each(opts, (option, key) => {
            if(typeof option == "string"){
                args[key] = option;
            } else if(_.isArray(option)){
                args[key] = option.join(",");
            } else {
                _.each(Object.keys(option), (filter) => {
                    args[`${key}[${filter}]`] = option[filter].join(",");
                })
            }
        })

        args['page'] = args['page'] ?? pageNumber;

        let response = await searchContent(args);
        let json = await response.json();

        setCurrentTerm(opts['s']);
        setCount(json.meta.count);
        setPages(json.meta.max_pages);
        setPageNumber(json.meta.current_page);
        setResults(json.data);
        setLoading(false);
        if ((opts['s'] && opts['s'].length)) {
            setHasSubmitCompleted(true);
        } else {
            setHasSubmitCompleted(false);
        }
    }

    return (
        <Formik
            initialValues={
                { s: currentTerm }
            }
            onSubmit={
                (values) => {
                    setPageNumber(1);
                    handleFilterSubmit(values);
                }
            }
            onReset={() => {
                setPageNumber(1);
                setCurrentTerm('');
                handleFilterSubmit({});
            }}
            validate={ values => {
                const errors = {};

                if (!values.s) {
                    errors.s = "Search term is required";
                }

                return errors;
            }}
        >
            {({handleSubmit, values, setFieldValue, handleReset, resetForm}) => (
                <Form onChange={() => {
                    // handleSubmit();
                }}>
                    <SearchBar hasSubmitCompleted={hasSubmitCompleted} term={currentTerm} handleReset={resetForm} setFieldValue={setFieldValue} setPageNumber={setPageNumber} />
                    <Results term={currentTerm} results={results} loading={loading} totalCount={count} pageNumber={pageNumber} setPageNumber={setPageNumber} maxPages={pages} handleSubmit={handleSubmit} />
                </Form>
            )}
        </Formik>
    )
}

function SearchBar({hasSubmitCompleted, term, handleReset, setFieldValue, setPageNumber}){
    return (
        <div>
            <div className="search-box">
                <div className="container mx-auto">
                    <div className="block md:flex items-center">
                        <div className="relative flex-grow">
                            <label className="sr-only">Search</label>
                            <Field
                                type="search"
                                name="s"
                                className="w-full p-5 bg-beige-25 type-preset-7 text-light-gray font-body focus:outline-none"
                                placeholder="Search for something"
                                onChange={(field) => {
                                    setFieldValue('s', field.target.value);
                                } }
                                autoComplete="off"
                            />
                        </div>
                        <div className="md:ml-8 mt-5 md:mt-0">
                            <button
                                type="submit"
                                onClick={() => {
                                    setPageNumber(1);
                                }}
                                className="btn btn--primary block w-full"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    <ErrorMessage name="s" component="div" className="text-cherry-red py-2 type-preset-8 p-5" />
                </div>
            </div>
        </div>
    )
}

function Results({term, results, loading, totalCount, pageNumber, setPageNumber, maxPages, handleSubmit}){
    return (
        <div className="result-search-list">
            {loading ?
                <div className="relative h-32 w-full">
                    <svg style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}} xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z" /><path d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" /></path></svg>
                </div>
            : (results && results.length) ?
                <div className="container mx-auto search-results newsroom-list">
                    <p className="font-body italic type-preset-6 text-navy mt-13 mb-7">Your search for "{term}" returned {totalCount} results:</p>
                    {results.map((post, idx) => (
                        <Post key={post.id} post={post} index={idx} />
                    ))}
                    <div className="search-results__pagination">
                        <Pagination
                            onPageChange={(pageNum) => {
                                window.scrollTo({
                                    top: (document.querySelector('[data-react-component=mainSearch]').getBoundingClientRect().top + window.pageYOffset - 180),
                                    behavior: 'smooth'
                                });
                                setPageNumber(pageNum);
                                handleSubmit();
                            }}
                            totalCount={totalCount}
                            currentPage={pageNumber}
                            maxPages={maxPages}
                        />
                    </div>
                </div>
            :
                <div className="container mx-auto">
                    <p className="border-b border-beige-200 text-navy mt-13 p-5">No results</p>
                </div>
            }
        </div>
    )
}

function Post({post, index}){
    return (
        <article
            id={`result_${index}`}
            className="search-result hover:bg-af-green-100 transition-colors duration md:px-8 max-w-804 md:-mx-8 josh-js"
            data-josh-anim-name="fadeIn"
            data-josh-delay="100ms"
        >
            <a href={post.url} className="block border-t border-beige-200 hover:border-transparent transition-colors duration pt-11 pb-14 max-w-750">
                <div className="type-preset-6-5 font-display font-bold text-af-green mb-2">{post.type}</div>
                <h2 className="type-preset-4 font-display font-bold text-navy mb-2" dangerouslySetInnerHTML={{__html: post.title}}></h2>
                <p className="type-preset-6 font-body text-navy" dangerouslySetInnerHTML={{__html: post.summary}}></p>
            </a>
        </article>
    )
}
