export function searchArticles (params = {})
{
    var url = new URL(window.location.origin + '/wp-json/americanforests/v1/article')
    Object
        .keys(params)
        .forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url);
}

export function searchContent (params = {})
{
    var url = new URL(window.location.origin + '/wp-json/americanforests/v1/search')
    Object
        .keys(params)
        .forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url);
}

export function searchTrees (params = {})
{
    var url = new URL(window.location.origin + '/wp-json/americanforests/v1/tree')
    Object
    .keys(params)
    .forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url);
}
