import React, { useEffect, useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import MobileMenu from '../MobileMenu/';



export default function MainMenu({
    logo,
    primaryNav,
    donateButton,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    let header = document.getElementById('site-header');
    if(header.classList.contains('menu-open')){
        header.classList.remove('menu-open');
    } else {
        header.classList.add('menu-open');
    }
  };

  const openSearch = () => {
    let component = document.getElementById('site-header').__x.$data;
    component.searchOpen = !component.searchOpen;
    setSearchOpen(component.searchOpen);
  }
  window.toggleSearch = function(state){
    setSearchOpen(state);
  }

  return (
    <div>
      <div className="flex">
        <button onClick={() => openSearch() } className={`search-toggle inline-block align-middle mr-2 rounded-full text-white font-bold h-33 w-33 py-7px px-4px circle inline-flex items-center ${(searchOpen ? 'bg-af-green' : '')}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </button>
        <button type="button" onClick={toggleMenu} className="inline-flex items-center justify-center h-33 w-33 py-7px px-4px text-white hover:text-white focus:outline-none focus:ring-none">
          <span className="sr-only">Open main menu</span>
          <div className="hamburger"><span></span><span></span><span></span><span></span></div>
        </button>
      </div>
      <MobileMenu
        items={primaryNav}
        toggleMenu={toggleMenu}
        openSearch={openSearch}
        openMenu={openMenu}
        donateButton={donateButton}
      />
    </div>
  )
}
